import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MaterialModule } from './material.module';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ApiServicesModule } from './core/api-services/api-services.module';
import { DataServicesModule } from './core/data-services/data-services.module';
import { AuthModule } from './auth/auth.module';

// Components
import { AppComponent } from './app.component';
import { RedirectComponent } from './redirect/redirect.component';

// NPM modules
import { MomentModule } from 'ngx-moment';
import { ClarityModule } from '@clr/angular';
import { CdsModule } from '@cds/angular';
import { TruncatePipe } from './core/pipes-directives/truncate.pipe';
import { httpInterceptorProviders } from './core/api-services/http-interceptors';
import { GraphQLModule } from './graphql.module';


@NgModule({
  declarations: [
    AppComponent,
    RedirectComponent,
    TruncatePipe
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ClarityModule,
    CdsModule,
    MaterialModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    ApiServicesModule,
    DataServicesModule,
    AuthModule,
    MomentModule,
    GraphQLModule
  ],
  providers: [
    TruncatePipe,
    ...httpInterceptorProviders
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
