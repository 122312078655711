import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
// import { LoginService } from './../api-services/login.service';

@Injectable()
export class AuthGuard  {
  constructor(
      // private loginService: LoginService,
      private router: Router) {}

  canActivate() {
    // return await this.checkLogin(state.url);
    // return true;

    if (localStorage.getItem('token')) {
      return true;
    }
    console.log("Authentication Failed");
    // Store the attempted URL for redirecting
    //this.loginService.redirectUrl = url;

    // Navigate to the login page with extras
    this.router.navigate(['/login']);
    return false;
  }
}